import Analytics from '@hh.ru/analytics-js';
import vacancyResponseCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete';
import vacancyResponseCompleteFeedExternal, {
    Label,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete_feed';
import { Alert, Button as MagritteButton } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Modal, { ModalTitle, ModalHeader, ModalContent, ModalFooter } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SendHHAnalytics from 'HH/SendHHAnalytics';
import Debug from 'HHC/Debug';
import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { TopLevelSite } from 'lux/models/topLevelSite';
import { useSelector } from 'lux/modules/useSelector';

export const sendAdvertisingAnalytics = (vacancyId: number): void => {
    SendHHAnalytics({ data: { goal: 'vacancy-response-company-url' } }).catch(console.error);
    // eslint-disable-next-line camelcase
    const externalEventParams = { label: 'ad-vacancy' as Label, params: { product_id: vacancyId } };
    vacancyResponseCompleteExternal(externalEventParams);
    vacancyResponseCompleteFeedExternal(externalEventParams);
};

const TrlKeys = {
    advertisingTitle: 'vacancy.advertising.title',
    advertisingText: 'vacancy.advertising.text',
    advertisingAction: 'vacancy.advertising.goforward',
    advertisingCancel: 'vacancy.advertising.cancel',
};

interface AdvertisingPopupProps {
    visible: boolean;
    onClose: (isCancel?: boolean) => void;
}

const AdvertisingPopup: TranslatedComponent<AdvertisingPopupProps> = ({ visible, onClose, trls }) => {
    const vacancy = useSelector((state) => state.vacancyResponsePopup?.vacancy);
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);

    const isMagritte = useExperiment('magritte_on_vacancy_response');

    if (!vacancy?.companyUrl) {
        Debug.log('out error', new Error('Advertising vacancy without companyUrl'), { id: vacancy?.vacancyId });
        return null;
    }

    const proceed = () => {
        sendAdvertisingAnalytics(vacancy.vacancyId);

        if (topLevelSite === TopLevelSite.ZP) {
            Analytics.addEventPixels('ZP_PURCHASE');
        }

        if (topLevelSite === TopLevelSite.RU) {
            Analytics.addEventPixels('B2C_VACANCY_RESPONSE_COMPLETE');
        }
    };

    const dismiss = () => {
        onClose(true);
    };

    if (isMagritte) {
        return (
            <Alert
                visible={visible}
                title={trls[TrlKeys.advertisingTitle]}
                description={trls[TrlKeys.advertisingText]}
                layout="vertical"
                icon={<ExclamationCircleFilledSize24 />}
                buttons={
                    <>
                        <MagritteButton
                            Element="a"
                            rel="nofollow"
                            href={vacancy.companyUrl}
                            mode="primary"
                            style="accent"
                            data-qa="vacancy-response-link-advertising"
                            onClick={proceed}
                        >
                            {trls[TrlKeys.advertisingAction]}
                        </MagritteButton>
                        <MagritteButton
                            mode="secondary"
                            style="accent"
                            data-qa="vacancy-response-link-advertising-cancel"
                            onClick={dismiss}
                        >
                            {trls[TrlKeys.advertisingCancel]}
                        </MagritteButton>
                    </>
                }
                onClose={dismiss}
            />
        );
    }

    return (
        <Modal visible={visible} onClose={dismiss} useBottomSheet>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.advertisingTitle]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className="vacancy-response-popup-body">{trls[TrlKeys.advertisingText]}</div>
            </ModalContent>
            <ModalFooter>
                <Button
                    kind={ButtonKind.Success}
                    Element="a"
                    rel="nofollow"
                    href={vacancy.companyUrl}
                    data-qa="vacancy-response-link-advertising"
                    onClick={proceed}
                >
                    {trls[TrlKeys.advertisingAction]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default translation(AdvertisingPopup);
